<template>
  <div class="">
    <v-observer tag="form" class="form-rounded-inputs" @submit.prevent="fnValidateInvoiceData()" ref="formInvoiceData">
      <div class="row animated fadeIn">
        <div class="col-12 col-md-6 col-lg-4">
          <v-validation rules="required|min:3|max:13" v-slot="{ errors }" :name=" 'RFC'">
            <div class="form-group">
              <label for="" class="" v-text=" 'RFC' "> </label>
              <div class="input-group ">
                <input type="text" class="form-control" v-model="newInvoiceData.key"
                  :class=" { 'is-invalid':errors.length > 0 } " :placeholder="'AR123456789'">
                <div class="input-group-append">
                  <span class="input-group-text rounded-right"> <strong>
                      <i class=" fa fa-id-card-alt "></i>
                    </strong> </span>
                </div>
                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </div>
            </div>
          </v-validation>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <v-validation rules="required|min:3" v-slot="{ errors }" :name=" 'Razón Social'">
            <div class="form-group">
              <label for="" class="" v-text=" 'Razón Social' "> </label>
              <div class="input-group ">
                <input type="text" class="form-control" v-model="newInvoiceData.business_name"
                  :class=" { 'is-invalid':errors.length > 0 } " :placeholder="'Ecart SA de CV'">
                <div class="input-group-append">
                  <span class="input-group-text rounded-right"> <strong>
                      <i class=" fa fa-keyboard "></i>
                    </strong> </span>
                </div>
                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </div>
            </div>
          </v-validation>
        </div>
        <div class="col-12 col-md-12 col-lg-4">
          <v-validation rules="required" v-slot="{ errors }" :name=" 'Uso de CFDI'">
            <div class="form-group">
              <label for="" class="" v-text=" 'Uso de CFDI' "> </label>
              <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
                <select class="custom-select" v-model="newInvoiceData.reason"
                  :class=" { 'is-invalid ':errors.length > 0 } ">
                  <option :value="null" selected v-text=" $t('general.form.select') "> </option>
                  <option :value="option.value" v-for=" (option,index) in cfdiList" :key="index" v-text="option.name">
                  </option>
                </select>
                <div class="input-group-append">
                  <span class="input-group-text rounded-right"> <strong>
                      <i class=" fa fa-briefcase "></i>
                    </strong> </span>
                </div>
              </div>
              <div class="invalid-feedback" v-if="errors.length > 0">
                {{ errors[0] }}
              </div>
            </div>
          </v-validation>
        </div>
        <div class="col-12">
          <v-validation :rules=" { 'required': (newInvoiceData.file == null ) } " v-slot="{ errors, validate }"
            :name=" 'Constancia de situación fiscal' ">
            <div class="form-group">
              <label for="" v-text=" 'Constancia de situación fiscal (maximo 1MB) ' "></label>
              <DropFile @validate="validate" @set-files="files =>  ( files[0] ? ( newInvoiceData.file = { name : files[0].name , file : files[0].file }) : newInvoiceData.file = null ) "
                fileName="CedulaFiscal" :class=" { 'is-invalid ':errors.length > 0 } " :accept=" ['application/pdf'] " />
              <div class="invalid-feedback" v-if="errors.length > 0">
                {{ errors[0] }}
              </div>
            </div>
          </v-validation>
        </div>
      </div>
    </v-observer>
  </div>
</template>


<script>
  import {
    mapState,
    mapMutations,
    mapActions,
    mapGetters,
  } from "vuex";
  import DropFile from '@/components/DropFile.vue';
  export default {
    components: {
      DropFile,
    },
    data() {
      return ({})
    },
    computed: {
      ...mapState('payments', ['newInvoiceData', 'cfdiList']),
    },
    methods: {
      // ...mapMutations('payments', ['']),
      ...mapActions('payments', ['fnCreateInvoice']),

      async fnValidateInvoiceData() {
        if (await this.$refs['formInvoiceData'].validate()) {
          this.fnCreateInvoice();
        }
      },

    }
  }
</script>