<template>
  <div>
    <b-modal v-model="modals.invoice" :title=" $t('general.form.invoiceInformation') " content-class="card form-rounded-inputs card-ecart" @hidden="resetInvoice()"
      header-class="card-header" cancel-variant="none" size="xl" @ok.prevent="fnValidateInvoice" centered>
      
      <LanguageLoader v-if="user.country == 'MX' " routeLanguage="./translations/payments/payments-taxes_" />
      <InvocieForm ref="newInvoice" />

      <template v-slot:modal-footer>
        <div class="d-flex w-100"> 
          <button @click=" modals.invoice = false ; resetInvoice() " class="btn ml-auto"  v-text=" $t('general.button.close') "></button>
          <button class=" btn btn-success " :disabled="loading.sendingNewPayment" @click="fnValidateInvoice()">
            <span v-text="( newInvoiceData.id == null )?  $t('general.button.add') : $t('general.button.update') "
              v-if=" !loading.sendingNewPayment "></span>
            <i class="fa fa-spinner fa-spin " v-else></i>
          </button>
        </div>
      </template>

    </b-modal>
  </div>
</template>

<script>
  import {
    mapState,
    mapActions,
    mapMutations,
    mapGetters
  } from 'vuex';
  import LanguageLoader from '@/components/TranslationsLoader.vue';

  import InvocieForm from "./payments-invoice-form";
  export default {
    name: 'PaymentsInvoiceModal',
    components: {
      InvocieForm,
      LanguageLoader
    },
    data() {
      return {
      }
    },
    computed: {
      ...mapState('session', ['user']),
      ...mapState('payments', ['modals','loading','newInvoiceData']),
    },
    methods: {
      // ...mapActions('payments', ['fnApiCreateDiscount']),
      ...mapMutations('payments', ['resetInvoice']),
      
      async fnValidateInvoice() {
        await this.$refs['newInvoice'].fnValidateInvoiceData();
      }
    },
    mounted() {

    },
  }
</script>

<style>
</style>