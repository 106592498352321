<template>
  <div class="border rounded p-2">

    <div v-if="fileList.length > 0">
      <div class="row align-items-center justify-content-center " v-for=" (file,index) in fileList" :key="index">
        <div class="col">
          <p class="m-0 f-w-500">
            <span v-text="file.name"></span>
          </p>
        </div>
        <div class="col-auto">
          <button class="btn btn-danger btn-xs " @click=" fnDeleteFile(index) "> <i class="fa fa-sm fa-trash"></i>
          </button>
        </div>
      </div>
    </div>

    <hr v-if=" multiple && fileList.length > 0 ">

    <div class="card m-0 shadow-none drag-container d-none d-md-block" :class=" dragOver ? ' bg-success':'' "
      id="DropZone" @dragover="fnOnDragOver" @dragleave="fnOnDragLeave" @drop="fnOnDrop "
      v-if=" multiple || (fileList.length == 0) ">
      <input type="file" @change="fnOnChange" id="assetsFieldHandle" class=" cursor-pointer " ref="newDragFile"
        :multiple="multiple" :accept="acceptList" />
      <div class="card-body py-4">
        <label for="assetsFieldHandle" class=" w-100 m-0">
          <div class="drag-message text-center">
            <p>
              <i class="fa fa-2x fa-cloud-upload-alt "></i>
            </p>
            <p :class=" maxSizeAlert ? 'text-danger' : 'text-info'" v-if="fileList.length == 0"
              v-text="$t('alertMessages.maxSize',{size: this.maxSize})"></p>
            <p v-if="fileList.length == 0">
              <span v-text=" $t('general.form.allowedFiles') + ': ' "></span>
              <span class="text-uppercase" v-text="acceptListString.join(',')"></span>
            </p>
            <p class="m-0" v-html="$t('general.form.dragAndDrop')"> </p>
          </div>
        </label>
      </div>
    </div>
    <div class="d-md-none ">
      <label for="assetsFieldHandle" class="btn btn-light w-100 m-0" v-if=" multiple || (fileList.length == 0) ">
        <i class="fa fa-cloud-upload-alt mr-2"></i>
        <span v-text="$t('general.button.uploadFile')"></span>
      </label>
    </div>
  </div>

</template>

<script>
  export default {
    props: {
      multiple: {
        type: Boolean,
        default: false,
      },
      accept: {
        type: Array,
        required: true,
      },
      maxSize: {
        type: Number,
        default: 1 //MB
      },
      fileName: {
        type: String,
        required: true,
      }
    },
    data() {
      return {
        dragOver: false,
        maxSizeAlert: false,
        fileList: [],
      }
    },
    computed: {
      acceptList() {
        return this.accept.join(',');
      },
      acceptListString() {
        let tempList = [];
        this.accept.map(type => {
          tempList.push(type.split('/').pop() );
        })
        return tempList;
      },
      maxSizeMB() {
        return this.maxSize * 1000000;
      }
    },
    watch: {
      fileList() {
        this.$emit('set-files', this.fileList);
        setTimeout(() => {
          this.$emit('validate');
        }, 200);
      }
    },
    methods: {
      fnDeleteFile(index) {
        this.fileList.splice(index, 1)
      },
      fnOnChange() {
        if (this.$refs.newDragFile.files.length > 0) {
          this.fnAddFiles([...this.$refs.newDragFile.files]);
          this.$refs.newDragFile.value = '';
        }
      },
      fnOnDrop(event) {
        event.preventDefault();
        this.fnAddFiles([...event.dataTransfer.files]);
        this.$refs.newDragFile.value = '';
        this.dragOver = false;
      },

      fnOnDragOver(event) {
        if (!this.dragOver) {
          this.dragOver = true;
        }
      },
      fnOnDragLeave(event) {
        if (this.dragOver) {
          this.dragOver = false;
        }
      },

      async fnAddFiles(tempFileList) {
        this.fileList = [];

        for (const index in [...tempFileList]) {
          if (tempFileList[index].size < this.maxSizeMB) {
            const ext = tempFileList[index].name.split('.').pop();
            if (this.acceptListString.includes(ext)) {
              if (this.multiple || (this.fileList.length == 0)) {
                let reader = new FileReader();
                reader.onloadend = ((event) => {
                  const tempName = ((index == 0) ? this.fileName : this.fileName + `(${index})`) + '.' + ext;
                  this.fileList.push({
                    type: ext,
                    name: tempName,
                    file: event.target.result
                  });
                });
                await reader.readAsDataURL(tempFileList[index]);
              }
            }
          } else {
            this.maxSizeAlert = true;
          }

        }
      }

    }
  }
</script>

<style lang="scss" scoped>
  .drag-container {
    border-style: dashed;
    border-width: .25rem;
  }

  #assetsFieldHandle {
    position: absolute;
    overflow: hidden;
    opacity: 0;
    height: 100%;
    width: 100%;
  }
</style>